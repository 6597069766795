import os from "os";

const hostname = os.hostname();

function getEnv(): "test" | "production" | undefined {
  if (hostname.indexOf("localhost") !== -1) {
    return "test";
  } else if (hostname.indexOf("junivers.test.iterate.no") !== -1) {
    return "test";
  } else if (hostname.indexOf("junivers.iterate.iterapp.no") !== -1) {
    return "test";
  } else if (hostname.indexOf("junivers.app.iterate.no") !== -1) {
    return "production";
  } else {
    return undefined;
  }
}

export default getEnv;
