/** @jsxImportSource @emotion/react */
import React, { FC, useEffect, useState, useRef } from "react";
import { sanity, urlFor } from "../utils/sanity";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import Library from "./Library";
import MoveableImage from "./MoveableImage";
import imagesButton from "../static/buttonIcons/imagesButton.svg";
import imagesButtonActive from "../static/buttonIcons/imagesButtonActive.svg";
import stickersButton from "../static/buttonIcons/stickersButton.svg";
import stickersButtonActive from "../static/buttonIcons/stickersButtonActive.svg";
import textButton from "../static/buttonIcons/textButton.svg";
import textButtonActive from "../static/buttonIcons/textButtonActive.svg";
import Swal from "sweetalert2";
import MoveableText from "./MoveableText";
import { v4 } from "uuid";

type Exercise = {
  name: string;
  image: string;
};

export type Position = {
  pos: [number, number];
  rotateZ: number;
  zoom: number;
};

export type Image = {
  uniqueId: string;
  image: string;
  position: Position;
  imageSize: number;
  layer: number;
};

export type Text = {
  uniqueId: string;
  textValue: string;
  position: Position;
  layer: number;
};

type ExerciseProps = {
  exerciseName: string;
};

function deleteItemFromList<T>(itemIndex: number, list: T[]) {
  return [...list.slice(0, itemIndex), ...list.slice(itemIndex + 1)];
}

function updateElement<T>(element, itemIndex: number, list: T[]) {
  return [...list.slice(0, itemIndex), { ...element }, ...list.slice(itemIndex + 1)];
}

const Exercise: FC<ExerciseProps> = props => {
  const [biggestZ, setBiggestZ] = useState(2);
  const [showStickers, setShowStickers] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [showTexts, setShowTexts] = useState(false);
  const [hideStickers, setHideStickers] = useState(false);
  const [hideImages, setHideImages] = useState(false);
  const [hideText, setHideText] = useState(false);
  const [textBackground, setTextBackground] = useState(false);

  const [exercise, setExercise] = useState({
    name: "Laster inn...",
    image: "",
  });

  const [stickerLibrary, setStickerLibrary] = useState({
    imagelist: [],
  });

  const [exerciseLibrary, setExerciseLibrary] = useState({
    imagelist: [],
  });

  const [bgImgWidth, setBgImgWidth] = useState(0);
  const [bgImgHeight, setBgImgHeight] = useState(0);

  const [stickers, setStickers] = useState<Array<Image> | undefined>([]);
  const [images, setImages] = useState<Array<Image> | undefined>([]);
  const [texts, setTexts] = useState<Array<Text>>([]);

  const bgImgRef = useRef(null);

  function handleResize() {
    if (bgImgRef.current) {
      setBgImgWidth(bgImgRef.current.width);
      setBgImgHeight(bgImgRef.current.height);
    }
  }

  useEffect(() => {
    // Bind the event listener
    window.addEventListener("resize", handleResize);
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    sanity
      .fetch(
        `*[_type=="exercise" && slug.current == "${props.exerciseName}"][0]{name, backgroundImage , imagelist, stickers, enableImageLibrary, enableText, textBackground} `
      )
      .then(response => {
        setExercise({ name: response.name, image: response.backgroundImage });
        setExerciseLibrary({ imagelist: response.imagelist });
        setHideStickers(response.stickers ? true : false);
        setHideImages(response.enableImageLibrary ? true : false);
        setHideText(response.enableText ? true : false);
        setTextBackground(response.textBackground ? true : false);
      })
      .catch(error => {
        console.log(error);
      });
    sanity
      .fetch(`*[_type=="stickers" && _id =="stickers"][0]{imagelist}`)
      .then(response => {
        setStickerLibrary(response);
      })
      .catch(error => {
        console.log(error);
      });
  }, [props.exerciseName]);

  return (
    <div
      css={css`
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #f4f4f5;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 80vh;
          width: 91vw;
          border-radius: 5px;
          box-sizing: border-box;
          background-color: white;

          * {
          }

          & > div {
            touch-action: none;
            will-change: transform;
            cursor: grab;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-touch-callout: none;
          }

          & > img {
            z-index: 0;
          }

          & > div > div {
            will-change: transform;
          }

          & > div > div > * {
            background-size: cover;
            background-position: center center;
          }

          & > div.disabled {
            opacity: 0.5;
          }
        `}
      >
        <Canvas id={"junivers-container"}>
          <BackgroundImage
            ref={bgImgRef}
            src={urlFor(exercise.image).width(1000).url()}
            unselectable={"on"}
            onLoad={handleResize}
          />
          {stickers.map((sticker, index) => {
            return (
              <MoveableImage
                onClick={e => {
                  if (biggestZ > sticker.layer) {
                    const newBiggestZ = biggestZ + 1;
                    setStickers(
                      updateElement(
                        {
                          image: sticker.image,
                          position: sticker.position,
                          layer: newBiggestZ,
                          imageSize: sticker.imageSize,
                        },
                        index,
                        stickers
                      )
                    );
                    setBiggestZ(newBiggestZ);
                  }
                }}
                imageSize={sticker.imageSize}
                key={sticker.uniqueId}
                uniqueId={sticker.uniqueId}
                image={sticker}
                layer={sticker.layer}
                deleteMe={() => {
                  setStickers(deleteItemFromList(index, stickers));
                }}
                setImage={image => {
                  setStickers(updateElement(image, index, stickers));
                }}
                bgImgHeight={bgImgHeight}
                bgImgWidth={bgImgWidth}
              />
            );
          })}
          {images.map((image, index) => {
            return (
              <MoveableImage
                onClick={e => {
                  if (biggestZ > image.layer) {
                    const newBiggestZ = biggestZ + 1;
                    setImages(
                      updateElement(
                        {
                          image: image.image,
                          position: image.position,
                          layer: newBiggestZ,
                          imageSize: image.imageSize,
                        },
                        index,
                        images
                      )
                    );
                    setBiggestZ(newBiggestZ);
                  }
                }}
                imageSize={image.imageSize}
                key={image.uniqueId}
                uniqueId={image.uniqueId}
                image={image}
                layer={image.layer}
                deleteMe={() => {
                  setImages(deleteItemFromList(index, images));
                }}
                setImage={image => {
                  setImages(updateElement(image, index, images));
                }}
                bgImgHeight={bgImgHeight}
                bgImgWidth={bgImgWidth}
              />
            );
          })}
          {texts.map((text, index) => {
            return (
              <MoveableText
                onClick={e => {
                  if (biggestZ > text.layer) {
                    const newBiggestZ = biggestZ + 1;
                    setTexts(
                      updateElement(
                        { textValue: text.textValue, position: text.position, layer: newBiggestZ },
                        index,
                        texts
                      )
                    );
                    setBiggestZ(newBiggestZ);
                  }
                }}
                key={text.uniqueId}
                uniqueId={text.uniqueId}
                textValue={text.textValue}
                textPos={text.position}
                layer={text.layer}
                textBackground={textBackground}
                deleteMe={() => {
                  setTexts(deleteItemFromList(index, texts));
                }}
                setText={text => {
                  setTexts(updateElement(text, index, texts));
                }}
              />
            );
          })}
        </Canvas>
        <div
          css={css`
            position: relative;
            align-self: start;
          `}
        >
          <ButtonsColumn>
            {!hideImages && (
              <LibraryButton
                key={`librarybtn2`}
                active={showImages}
                src={showImages ? imagesButtonActive : imagesButton}
                id={"show-images-button"}
                onClick={() => {
                  Swal.close();
                  setShowStickers(false);

                  setShowImages(!showImages);
                }}
              />
            )}
            {showImages ? (
              <Library
                headerTitle={"LEGG TIL BILDE"}
                setBiggestZ={setBiggestZ}
                biggestZ={biggestZ}
                images={images}
                setShowLibrary={show => {
                  setShowImages(show);
                }}
                setImages={item => {
                  setImages(item);
                }}
                imageLibrary={exerciseLibrary.imagelist}
              />
            ) : null}
            {!hideStickers && (
              <LibraryButton
                key={`librarybtn1`}
                active={showStickers}
                src={showStickers ? stickersButtonActive : stickersButton}
                id={"show-stickers-button"}
                onClick={() => {
                  Swal.close();
                  setShowImages(false);

                  setShowStickers(!showStickers);
                }}
              />
            )}
            {showStickers ? (
              <Library
                headerTitle={"LEGG TIL FIGUR"}
                setBiggestZ={setBiggestZ}
                biggestZ={biggestZ}
                images={stickers}
                setShowLibrary={show => {
                  setShowStickers(show);
                }}
                setImages={item => {
                  setStickers(item);
                }}
                stickerLibrary={stickerLibrary.imagelist}
              />
            ) : null}

            {!hideText && (
              <TextButton
                key={`textbtn1`}
                active={showTexts}
                src={showTexts ? textButtonActive : textButton}
                onClick={() => {
                  if (Swal.isVisible()) {
                    Swal.close();
                  } else {
                    setShowStickers(false);
                    setShowImages(false);
                    setShowTexts(true);
                    void Swal.fire({
                      title: "TEKST",
                      input: "textarea",
                      inputValidator: value => {
                        if (value == "") {
                          return "Husk å skrive noe!";
                        }
                      },
                      showCancelButton: true,
                      confirmButtonText: "Legg til",
                      cancelButtonText: "Avbryt",
                      inputPlaceholder: "Skriv her...",
                      reverseButtons: true,
                      customClass: { container: "swalModal", popup: "swalModal" },
                      target: document.getElementById("junivers-container"),
                    })
                      .then(result => {
                        const newText = result.value;
                        if (result.isConfirmed) {
                          const uniqueId = v4();
                          const newTextsList: Text[] = [
                            ...texts,
                            {
                              uniqueId: uniqueId,
                              textValue: newText,
                              position: { pos: [0, 0], rotateZ: 0, zoom: 1 },
                              layer: 1,
                            },
                          ];
                          setTexts(newTextsList);
                        }
                      })
                      .then(() => {
                        setShowTexts(false);
                      });
                  }
                }}
              />
            )}
          </ButtonsColumn>
        </div>
      </div>
    </div>
  );
};

const Canvas = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
`;

const BackgroundImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  pointer-events: none;
`;

const LibraryButton = styled.img<{ active: boolean }>`
  margin: 10px;
  padding: 3px;
  ${props => (props.active ? "background-color: #0047FF;" : "background-color: white;")};
  border-radius: 50px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
`;

const TextButton = styled.img<{ active: boolean }>`
  width: 45px;
  margin: 10px;
  padding: 3px;
  ${props => (props.active ? "background-color: #0047FF;" : "background-color: white;")};
  border-radius: 50px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
`;

const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  right: -35px;
`;

export default Exercise;
