import React from "react";
import Exercise from "./components/Exercise";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App(): JSX.Element {
  return (
    <Router>
      <Switch>
        <Route
          path="/exercise/:exerciseName"
          component={props => <Exercise exerciseName={props.match.params.exerciseName} />}
        />
      </Switch>
    </Router>
  );
}

export default App;
