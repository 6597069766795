import Client from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import getEnv from "./getEnv";

let projectId = "";
let dataset = "";
if (getEnv() && getEnv() == "production") {
  projectId = "3gxwv6lg";
  dataset = "production";
} else if (getEnv() && getEnv() == "test") {
  projectId = "3gxwv6lg";
  dataset = "test";
} else {
  projectId = "";
  dataset = "";
}

export const sanity = Client({
  projectId,
  dataset,
  useCdn: true,
});

const urlBuilderFactory = imageUrlBuilder(sanity);
export interface Reference {
  _ref: string;
  _type: "_reference";
}

export function urlFor(image: SanityImageSource) {
  return urlBuilderFactory.image(image);
}
