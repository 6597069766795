/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React, { useState, useRef, useEffect, FC } from "react";
import { useGesture } from "react-use-gesture";
import { Position, Text } from "./Exercise";
import styled from "@emotion/styled/macro";
import ReactSlider from "react-slider";
import Swal from "sweetalert2";
import { ReactComponent as EditButton } from "../static/buttonIcons/editButton.svg";
import { ReactComponent as DeleteButton } from "../static/buttonIcons/deleteButton.svg";

type MovableElementProps = {
  position: Position;
  setPosition: (newPosition: Position) => void;
  layer: number;
  highlight: boolean;
  setHighlight: (highlight: boolean) => void;
  disableSlider: boolean;
  deleteMe: () => void;
  setText?: (newText: Text) => void;
  textValue?: string;
  alwaysFront?: boolean;
  imageSize?: number;
  uniqueId: string;
};

export const MoveableElement: FC<MovableElementProps> = ({
  position,
  setPosition,
  children,
  layer,
  highlight,
  setHighlight,
  disableSlider,
  deleteMe,
  textValue,
  setText,
  alwaysFront,
  imageSize,
  uniqueId,
}) => {
  const [pos, setPos] = useState<[number, number]>(position.pos);
  const [zoom, setZoom] = useState(position.zoom);
  const [rotate, setRotate] = useState(position.rotateZ);
  // TODO: Render UI based on this
  const [, setDragging] = useState(false);
  const [, setPinching] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    setPos(position.pos);
    setZoom(position.zoom);
    setRotate(position.rotateZ);
  }, [position.pos, position.rotateZ, position.zoom]);

  const domTarget = useRef(null);
  const deleteRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (
        domTarget.current &&
        deleteRef.current &&
        !domTarget.current.contains(event.target as Node) &&
        !deleteRef.current.contains(event.target as Node)
      ) {
        setShowDelete(false);
        setHighlight(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [domTarget, deleteRef, setHighlight, setShowDelete]);

  useGesture(
    {
      onDragStart: () => {
        setDragging(true);
      },
      onDrag: ({ movement, tap }) => {
        if (!tap) {
          // Prevent scroll on touch screens while dragging:
          document.ontouchmove = function (e) {
            e.preventDefault();
          };

          setPos([movement[0], movement[1]]);
        }
      },
      onDragEnd: ({ tap }) => {
        document.ontouchmove = function () {
          return true;
        };
        if (!tap) {
          setPosition({
            pos,
            rotateZ: rotate,
            zoom,
          });
        }

        setDragging(false);
      },
      onPinchStart: () => {
        setPinching(true);
      },
      onPinch: ({ offset: [, a] }) => {
        setRotate(a);
      },
      onPinchEnd: () => {
        setPosition({
          pos,
          rotateZ: rotate,
          zoom,
        });
        setPinching(false);
      },
    },
    {
      domTarget,
      eventOptions: { passive: false },
      drag: {
        initial: () => [...pos],
        filterTaps: true,
      },
    }
  );

  const Track = props => <StyledTrack {...props} />;

  const Thumb = props => <StyledThumb {...props}></StyledThumb>;

  return (
    <div
      css={css`
        position: absolute;
        z-index: ${alwaysFront ? 900000000 : layer};
      `}
    >
      <div
        css={css`
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <div
          ref={domTarget}
          css={css`
            position: absolute;
            transform: translate3d(${pos[0]}px, ${pos[1]}px, 0) rotateZ(${rotate}deg) scale(${zoom});
          `}
          onClick={() => {
            setHighlight(!highlight);
            setShowDelete(!showDelete);
          }}
        >
          <div
            css={css`
              position: relative;
            `}
          >
            {children}
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                position: absolute;
                top: -2vh;
                right: -2vw;
                transform: scale(${1 / zoom});
              `}
            >
              {showDelete && textValue && (
                <Edit
                  onClick={() => {
                    setShowDelete(false);
                    void Swal.fire({
                      title: "TEKST",
                      input: "textarea",
                      showCancelButton: true,
                      confirmButtonText: "Legg til ",
                      cancelButtonText: "Avbryt",
                      inputPlaceholder: "Skriv her...",
                      inputValue: textValue,
                      customClass: { container: "swalModal", popup: "swalModal" },
                      reverseButtons: true,
                      target: document.getElementById("junivers-container"),
                    }).then(result => {
                      const newText = result.value;
                      if (result.isConfirmed) {
                        setText({
                          textValue: newText,
                          position: position,
                          layer: layer,
                          uniqueId: uniqueId,
                        });
                      }
                    });
                  }}
                />
              )}
              {showDelete && (
                <Delete
                  ref={deleteRef}
                  onClick={() => {
                    setShowDelete(false);
                    void Swal.fire({
                      title: "Sikker på at du vil slette dette?",
                      showCancelButton: true,
                      confirmButtonText: "Slett",
                      cancelButtonText: "Behold",
                      reverseButtons: true,
                      customClass: { container: "swalModal", popup: "swalModal" },
                      target: document.getElementById("junivers-container"),
                    }).then(result => {
                      if (result.value) {
                        deleteMe();
                      }
                    });
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div
          css={css`
            position: absolute;
            z-index: 9999999999;
            bottom: ${imageSize ? -imageSize / 1.5 + (imageSize / 5 - zoom) : -20 + (14 - zoom)}vw;
            transform: translate3d(${pos[0]}px, ${pos[1]}px, 0);
          `}
        >
          {showDelete && !disableSlider ? (
            <StyledSlider
              posX={pos[0]}
              posY={pos[1]}
              min={0.1}
              max={2}
              step={0.1}
              value={position.zoom}
              onChange={value => {
                if (typeof value === 'number') {
                  setZoom(value);
                }
              }}
              onAfterChange={() => {
                setPosition({
                  pos,
                  rotateZ: rotate,
                  zoom,
                });
              }}
              renderTrack={Track}
              renderThumb={Thumb}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const StyledSlider = styled(ReactSlider)<{ posX: number; posY: number }>`
  width: 200px;
  height: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border: 10px solid white;
  background: white;
  border-radius: 3px;
`;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: grey;
  border-radius: 999px;
  margin: 3px;
`;

const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
`;

const Delete = styled(DeleteButton)`
  user-select: none;
  background-color: #ffffff;
  border: 3px white solid;
  border-radius: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 3px;
`;

const Edit = styled(EditButton)`
  margin: 0 0 10px 0;
  width: 36px;
  height: 36px;
  cursor: pointer;
  user-select: none;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
`;
