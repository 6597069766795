import React, { FC, useState } from "react";
import "../index.css";
import styled from "@emotion/styled/macro";
import { MoveableElement } from "./MoveableElement";
import { urlFor } from "../utils/sanity";
import { Image, Position } from "./Exercise";

type MoveableImageProps = {
  image: Image;
  layer: number;
  imageSize: number;
  deleteMe: () => void;
  setImage: (item: Image) => void;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  uniqueId: string;
  bgImgHeight: number;
  bgImgWidth: number;
};

const MoveableImage: FC<MoveableImageProps> = props => {
  const [highlight, setHighlight] = useState<boolean>(false);
  const {
    image,
    layer,
    deleteMe,
    setImage,
    onClick,
    uniqueId,
    imageSize,
    bgImgHeight,
    bgImgWidth,
  } = props;
  const imagePos = image.position;

  function updateImage(incomingPos: Position) {
    const newPos = {
      pos: incomingPos.pos,
      rotateZ: incomingPos.rotateZ,
      zoom: incomingPos.zoom,
    };
    setImage({
      layer: layer,
      image: image.image,
      position: newPos,
      uniqueId: uniqueId,
      imageSize: imageSize,
    });
  }

  return (
    <MoveableElement
      uniqueId={uniqueId}
      disableSlider={false}
      position={imagePos}
      setPosition={updateImage}
      setHighlight={highlight => {
        setHighlight(highlight);
      }}
      highlight={highlight}
      layer={layer}
      deleteMe={deleteMe}
      imageSize={imageSize}
    >
      <Container highlight={highlight} onClick={onClick}>
        {image.image ? (
          <NonInteractiveImage
            imageSize={imageSize}
            bgImgWidth={bgImgWidth}
            bgImgHeight={bgImgHeight}
            src={urlFor(image.image).width(500).url()}
          />
        ) : null}
      </Container>
    </MoveableElement>
  );
};

export default MoveableImage;

const Container = styled.div<{ highlight: boolean }>`
  ${props => (props.highlight ? "border: 3px solid #53ABFC;" : "border: 3px solid transparent;")}
`;

const NonInteractiveImage = styled.img<{
  imageSize: number;
  bgImgWidth: number;
  bgImgHeight: number;
}>`
  height: auto;
  pointer-events: none;
  position: relative;
  width: ${props =>
    props.imageSize && props.bgImgWidth > 0 && props.bgImgHeight > 0
      ? `${(props.bgImgWidth + props.bgImgHeight) * (0.4 * (props.imageSize / 100))}px;`
      : `20vw`};
`;
