import React, { FC, useState } from "react";
import "../index.css";
import styled from "@emotion/styled/macro";
import { MoveableElement } from "./MoveableElement";
import { Position, Text } from "./Exercise";

type MoveableTextProps = {
  textPos: Position;
  textValue: string;
  layer: number;
  deleteMe: () => void;
  setText: (newText: Text) => void;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  uniqueId: string;
  textBackground: boolean;
};

const MoveableText: FC<MoveableTextProps> = props => {
  const [highlight, setHighlight] = useState<boolean>(false);
  const { textPos, textValue, layer, deleteMe, setText, onClick, uniqueId } = props;

  const textWidth: number = textValue
    ? textValue.split("\n").reduce((prev, curr) => {
        if (curr.length > prev) {
          return curr.length;
        }
        return prev;
      }, 0)
    : 0;

  const updateText = (newText: string, incomingPos: Position) => {
    let updatedText = textValue;
    if (newText) {
      updatedText = textValue;
    }
    const newPos = {
      pos: incomingPos.pos,
      rotateZ: incomingPos.rotateZ,
      zoom: incomingPos.zoom,
    };

    setText({
      textValue: updatedText,
      position: newPos,
      layer: layer,
      uniqueId: uniqueId,
    });
  };

  return (
    <MoveableElement
      disableSlider={false}
      position={textPos}
      setPosition={newPos => updateText(textValue, newPos)}
      layer={layer}
      setHighlight={highlight => {
        setHighlight(highlight);
      }}
      highlight={highlight}
      deleteMe={deleteMe}
      setText={setText}
      textValue={textValue}
      alwaysFront={true}
      uniqueId={uniqueId}
    >
      <Container onClick={onClick}>
        <TextParagraph highlight={highlight} textwidth={textWidth} textBackground={props.textBackground}>
          {textValue}
        </TextParagraph>
      </Container>
    </MoveableElement>
  );
};

export default MoveableText;

const Container = styled.div`
  border: 3px solid transparent;

  align-items: center;

  display: flex;
  justify-content: center;
`;

const TextParagraph = styled.p<{
  highlight: boolean;
  textwidth: number;
  textBackground: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 10px 10px;
  justify-content: center;

  white-space: pre-wrap;
  position: relative;
  outline: none;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  font-family: "Eat well Chubby", "Concert One";
  line-height: 25px;
  ${props => props.textBackground ? `background-color: white` : ``};
  white-space: pre;
  &:hover {
    background-color: lightgrey;
  }

  ${props => (props.highlight ? "border: 3px solid #53ABFC;" : "border: none;")}

  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
`;
