/** @jsxImportSource @emotion/react */

import React, { FC, useRef } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { Image } from "./Exercise";
import { urlFor } from "../utils/sanity";
import { v4 } from "uuid";
import { ReactComponent as CloseButton } from "../static/buttonIcons/closeButton.svg";

type ImageList = {
  _key: string;
  _type: string;
  imageSize: number;
  asset: string;
};

type LibraryProps = {
  imageLibrary?: Array<ImageList>;
  stickerLibrary?: Array<string>;
  setImages: React.Dispatch<React.SetStateAction<Image[]>>;
  setShowLibrary: React.Dispatch<React.SetStateAction<boolean>>;
  images: Array<Image>;
  setBiggestZ: (newBiggestZ: number) => void;
  biggestZ: number;
  headerTitle: string;
};

const Library: FC<LibraryProps> = props => {
  const {
    setImages,
    images,
    imageLibrary,
    stickerLibrary,
    setShowLibrary,
    setBiggestZ,
    biggestZ,
    headerTitle,
  } = props;

  const libraryRef = useRef(null);

  return (
    <div
      ref={libraryRef}
      css={css`
        position: absolute;
        top: -40px;
        right: 80px;
        display: grid;
        height: 80vh;
        border: 2px solid black;
        background-color: white;
        filter: drop-shadow(0px 4px 120px rgba(0, 0, 0, 0.15));
        grid-template-rows: auto 1fr;
        overflow-y: scroll;
        overflow-x: hidden;
        justify-items: center;
        grid-template-areas: "header" "body";
      `}
    >
      <div
        css={css`
          top: 0px;
          position: sticky;
          position: -webkit-sticky;
          background-color: white;
          display: grid;
          width: 86.5vw;
          grid-area: header;
          margin-bottom: 30px;
          grid-template-areas: ". title . button";
          grid-template-columns: 30vw 1fr 20vw 10vw;
          grid-auto-flow: column;
          align-items: center;
          grid-row: 1;
        `}
      >
        <h1
          css={css`
            grid-area: title;
            align-self: center;
            justify-self: center;
          `}
        >
          {headerTitle}
        </h1>
        <CloseButton
          onClick={() => {
            setShowLibrary(false);
          }}
          css={css`
            grid-area: button;
            justify-self: center;
          `}
        />
      </div>
      <div
        css={css`
          display: grid;
          grid-area: body;
          justify-items: center;
          align-items: center;
          width: 86.5vw;
          row-gap: 10px;
          column-gap: 30px;
          grid-template-columns: repeat(auto-fit, 12vw);
          grid-auto-rows: minmax(min-content, max-content);
        `}
      >
        {imageLibrary &&
          imageLibrary.map(imgObj => {
            const uniqueKey = v4();
            return (
              <Sticker
                key={uniqueKey}
                src={urlFor(imgObj).width(1000).url()}
                onClick={() => {
                  setShowLibrary(false);
                  const newBiggestZ = biggestZ + 1;
                  const uniqueId = v4();
                  setImages([
                    ...images.slice(),
                    {
                      uniqueId: uniqueId,
                      image: imgObj.asset,
                      layer: newBiggestZ,
                      imageSize: imgObj.imageSize ? imgObj.imageSize : 15,
                      position: {
                        pos: [0, 0],
                        rotateZ: 0,
                        zoom: 1,
                      },
                    },
                  ]);
                  setBiggestZ(newBiggestZ);
                }}
              />
            );
          })}
        {stickerLibrary &&
          stickerLibrary.map(imgObj => {
            const uniqueKey = v4();
            return (
              <Sticker
                key={uniqueKey}
                src={urlFor(imgObj).width(1000).url()}
                onClick={() => {
                  setShowLibrary(false);
                  const newBiggestZ = biggestZ + 1;
                  const uniqueId = v4();
                  setImages([
                    ...images.slice(),
                    {
                      uniqueId: uniqueId,
                      image: imgObj,
                      layer: newBiggestZ,
                      imageSize: 20,
                      position: {
                        pos: [0, 0],
                        rotateZ: 0,
                        zoom: 1,
                      },
                    },
                  ]);
                  setBiggestZ(newBiggestZ);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Library;

const Sticker = styled.img`
  max-width: 10vw;
  max-height: 10vw;
  padding: 10px 0 10px 20px;
`;
